// src/templates/pages/DefaultTemplate.js

import React from 'react'
import { graphql } from 'gatsby'
import Seo from 'gatsby-plugin-wpgraphql-seo'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Layout from '../../components/Layout.js'
import ResourcesHeader from '../../components/ResourcesHeader'
import AllLayouts from '../../components/AllLayouts.js'

function DefaultTemplate({ data }) {
  const pageData = data.wpPage
  const pageHeader = pageData.template.detailPage.pageHeader
  const layoutBlocks = pageData.template.pageBuilder.layoutBlocks || []
  const heroSrc = pageHeader.heroImage
    ? getImage(pageHeader.heroImage.localFile)
    : null

  return (
    <Layout>
      <Seo post={data.wpPage} />

      <section className="page-intro">
        <div className="container relative">
          <div className="page-intro-inner relative z-10">
            <ResourcesHeader
              label={pageHeader.titleAboveHeadline ? pageData.title : null}
              title={pageHeader.headline ? pageHeader.headline : null}
              description={
                pageHeader.description ? pageHeader.description : null
              }
            />
          </div>
          {/* TODO: Possible animation of these rings in a future pass */}
          <div className="absolute top-1/2 left-1/2 -z-[1] mx-auto aspect-square w-3/4 -translate-y-1/2 -translate-x-1/2 bg-rings-rotate bg-cover"></div>
        </div>
        <div className="container relative z-10">
          {heroSrc && (
            <GatsbyImage
              className="scroll-target mt-responsive-xl-80"
              image={heroSrc}
              alt={pageHeader.heroImage.altText}
            />
          )}
        </div>
      </section>

      {layoutBlocks.map((layout, index) => {
        return <AllLayouts key={index} layoutData={layout} />
      })}
    </Layout>
  )
}

export default DefaultTemplate

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      seo {
        ...SeoFragment
      }
      id
      title
      uri
      template {
        ... on WpDefaultTemplate {
          detailPage {
            pageHeader {
              headline
              description
              titleAboveHeadline
              heroImage {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      height: 1110
                      width: 1850
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                      transformOptions: { cropFocus: CENTER }
                    )
                  }
                }
              }
            }
          }
          pageBuilder {
            ...PageBuilderFragment
          }
        }
      }
    }
  }
`
