// src/components/ResourcesHeader.js

import React from 'react'
import parse from 'html-react-parser'
import cx from 'classnames'
import PropTypes from 'prop-types'

function ResourcesHeader(props) {
  return (
    <section className="page-intro container relative">
      <div
        className={cx('pt-responsive-xl-80 text-center', {
          'mb-responsive-xl-32 border-b border-granite-200 pb-responsive-xl-80':
            props.hasBorder,
        })}
      >
        {props.label && (
          <p className="mb-responsive-xl-16 inline-block border-b border-fuscia-full pb-responsive-xl-8 font-medium text-sm uppercase tracking-wide">
            {props.label}
          </p>
        )}

        {props.title && (
          <h1 className="headline-xxl mb-responsive-xl-24">
            {parse(props.title)}
          </h1>
        )}

        {props.description && (
          <div className="container-inner">
            <p className="text-xl">{parse(props.description)}</p>
          </div>
        )}
      </div>
    </section>
  )
}

export default ResourcesHeader

ResourcesHeader.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  hasBorder: PropTypes.bool,
  label: PropTypes.string,
}
